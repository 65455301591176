import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getVehicleDetailById } from "../Api"; // Import the API method for fetching vehicle details by ID

export const fetchVehicleDetailById = createAsyncThunk(
  "vehicleDetailById/fetchVehicleDetailById",
  async (vehicleId) => {
    const response = await getVehicleDetailById(vehicleId);
    return response;
  }
);

const vehicleDetailByIdSlice = createSlice({
  name: "vehicleDetailById",
  initialState: {
    vehicleDetail: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehicleDetailById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchVehicleDetailById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vehicleDetail = action.payload;
      })
      .addCase(fetchVehicleDetailById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectVehicleDetailById = (state) => state.vehicleDetailById;
export default vehicleDetailByIdSlice.reducer;
