import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import {
  fetchGiftRequests,
  // acceptGiftRequest,
  // rejectGiftRequest,
} from "../app/features/redeemRequestsSlice";
import {
  updateGiftRequest, // Import the updateGiftRequest async thunk
} from "../app/features/giftStatusSlice";

function GiftRequestsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("Pending");
  const [isModifyMode, setIsModifyMode] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const { giftRequests, isLoading, error } = useSelector(
    (state) => state.redeemRequests
  );
  const updateLoading = useSelector((state) => state.giftStatus.isLoading);
  console.log(updateLoading, "ooo00o");
  useEffect(() => {
    // Fetch gift requests when the component mounts
    dispatch(fetchGiftRequests());
    console.log(giftRequests);
  }, [dispatch]);
  const handleModify = (id) => {
    setIsModifyMode(true);
    setSelectedRequestId(id);
  };

  const handleCancelModify = () => {
    setIsModifyMode(false);
    setSelectedRequestId(null);
  };

  const handleAccept = (id) => {
    // Update the status of the gift request to 'accepted'
    dispatch(updateGiftRequest({ orderId: id, newStatus: "accepted" }));
    dispatch(fetchGiftRequests());
    setIsModifyMode(false);
    setSelectedRequestId(null);
  };

  const handleReject = (id) => {
    // Update the status of the gift request to 'rejected'
    dispatch(updateGiftRequest({ orderId: id, newStatus: "rejected" }));
    dispatch(fetchGiftRequests());
    setIsModifyMode(false);
    setSelectedRequestId(null);
  };

  const navigateToUserExpenses = (userId) => {
    navigate(`/home/user/${userId}`);
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-center text-2xl font-bold mb-4">
          your data is loading, please wait...
        </h1>
        <div className="mb-8">
          <BeatLoader size={50} color={"black"} loading={true} />
        </div>
      </div>
    );
  }

  if (updateLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-center text-2xl font-bold mb-4">
          Updating Status, please wait...
        </h1>
        <div className="mb-8">
          <BeatLoader size={50} color={"black"} loading={true} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-4xl font-bold">
        Error: {error}
      </div>
    );
  }
  if (!giftRequests || giftRequests.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen text-4xl font-bold">
        There are no Requests available.
      </div>
    );
  }

  const formatDate = (inputDate) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  };

  const handleFilter = (status) => {
    setFilter(status);
  };

  const filteredGiftRequests = giftRequests.filter(
    (request) => request.status === filter
  );

  return (
    <div className="p-4">
      <div className="flex  bg-slate-300 rounded-md ps-4 mb-4 justify-between">
        <h2 className="text-2xl font-semibold  ">Gift Requests</h2>

        <div className="flex bg-slate-100  ">
          <button
            onClick={() => handleFilter("Pending")}
            className={`px-4 py-2 mx-1 ${
              filter === "Pending" ? "bg-blue-500 text-white" : "bg-gray-300"
            } rounded-md`}
          >
            Pending
          </button>
          <button
            onClick={() => handleFilter("accepted")}
            className={`px-4 py-2 mx-1 ${
              filter === "accepted" ? "bg-green-500 text-white" : "bg-gray-300"
            } rounded-md`}
          >
            Accepted
          </button>
          <button
            onClick={() => handleFilter("rejected")}
            className={`px-4 py-2 mx-1 ${
              filter === "rejected" ? "bg-red-500 text-white" : "bg-gray-300"
            } rounded-md`}
          >
            Rejected
          </button>
        </div>
      </div>
      <div className="overflow-y-auto h-96">
        <table className="w-full border-collapse border ">
          <thead className="">
            <tr className="bg-gray-200">
              <th className="p-2 border">Product</th>
              <th className="p-2 border">User Name</th>
              <th className="p-2 border">Mobile No.</th>
              <th className="p-2 border">Points</th>
              <th className="p-2 border">Date</th>
              <th className="p-2 border">Status</th>
              <th className="p-2 border">Action</th>
            </tr>
          </thead>
          <tbody className="overflow-y-auto h-80">
            {filteredGiftRequests.map((request, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="p-2 border">
                  <div className="flex items-center space-x-2">
                    <img
                      src={request.fileName}
                      alt={request.productName}
                      className="w-12 h-12 object-cover"
                    />
                    <span>{request.productName}</span>
                  </div>
                </td>
                <td className="p-2 border">{request.username}</td>
                <td className="p-2 border">{request.mobileNumber}</td>
                <td className="p-2 border">{request.redeemPoints}</td>
                <td className="p-2 border">
                  {formatDate(request.createdDate)}
                </td>
                <td className="p-2 border">{request.status}</td>
                <td className="p-2 border">
                  {isModifyMode && selectedRequestId === request.id ? (
                    <>
                      <button
                        onClick={() => handleAccept(request.id)}
                        className="px-2 py-1 bg-green-500 text-white rounded mx-1"
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => handleReject(request.id)}
                        className="px-2 py-1 mr-2 bg-red-500 text-white rounded"
                      >
                        Reject
                      </button>
                      <button
                        onClick={handleCancelModify}
                        className="px-2 py-1 bg-blue-500 text-white rounded"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      {request.status === "Pending" && (
                        <button
                          onClick={() => handleModify(request.id)}
                          className="px-2 py-1 bg-blue-500 text-white rounded"
                        >
                          Modify
                        </button>
                      )}
                      <button
                        onClick={() => navigateToUserExpenses(request.userID)}
                        className="mx-2 px-2 py-1 bg-green-400 text-white rounded"
                      >
                        View Expenses
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GiftRequestsPage;
