import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserDetailByID } from "../Api";

export const fetchUserDetailByID = createAsyncThunk(
  "userDetailById/fetchUserDetailByID",
  async (userId) => {
    const response = await getUserDetailByID(userId);
    return response; // Assuming the response contains user details
  }
);

const userDetailByIdSlice = createSlice({
  name: "userDetailById",
  initialState: {
    userDetail: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetailByID.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserDetailByID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetail = action.payload.data;
      })
      .addCase(fetchUserDetailByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectUserDetail = (state) => state.userDetailById;

export default userDetailByIdSlice.reducer;
