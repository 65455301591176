import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from '../app/features/addProductSlice'; 
import { ToastContainer, toast } from 'react-toastify';
function AddProduct() {
  const dispatch = useDispatch();
  const { loading, error,success  } = useSelector((state) => state.products);

  const [product, setProduct] = useState({
    
    Name: "",
    Point: "",
    Type: "Product", 
    FileName: "null", 
    ContentType: "",
    Filebase64: "",
    image: null,
  });

  // const handleImage = (e) => {
  //   const updatedProduct = {...product };
  //   updatedProduct.image = e.target.files[0];
  //   setProduct(updatedProduct);
  // };
  const handleImage = (e) => {
    const file = e.target.files[0];
  
    // Create a new FileReader
    const reader = new FileReader();
  
    // Set up the reader onload event
    reader.onloadend = () => {
      const updatedProduct = {
        ...product,
        FileName: file.name,
        ContentType: file.type,
        image: file,
        Filebase64: reader.result.split(',')[1] // Extract base64 string from data URL
      };
  
      // Update the product state with the updatedProduct object
      setProduct(updatedProduct);
    };
  
    // Read the selected file as a data URL, triggering the onloadend event
    reader.readAsDataURL(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Product:", product);
    dispatch(addProduct(product));
  };

  if (error) {
    console.error("Error:", error);
  }

  // Display success message using toastify
  if (success) {
    toast.success("Product added successfully!");
  }


  return (
    <div
      className="flex justify-center items-center  "
      // style={{ height: "calc(100vh - 64px)" }}
    >
      <form
        onSubmit={handleSubmit}
        className="w-full sm:w-96 flex flex-col items-center m-4"
      >
        <h1 className="text-2xl font-semibold mb-4  bg-slate-300 rounded-md p-4">
          Add Product Detail
        </h1>
        <div className="mb-6 w-full">
          <label htmlFor="productName" className="block font-semibold">
            Product Name
          </label>
          <input
            type="text"
            id="productName"
            value={product.Name}
            onChange={(e) => setProduct({ ...product, Name: e.target.value })}
            className="border border-gray-300 p-2 rounded-lg w-full"
            required
          />
        </div>
        <div className="mb-6 w-full">
          <label htmlFor="pointsNeeded" className="block font-semibold">
            Points Needed
          </label>
          <input
            type="number"
            id="pointsNeeded"
            value={product.Point}
            onChange={(e) =>
              setProduct({ ...product, Point: e.target.value })
            }
            className="border border-gray-300 p-2 rounded-lg w-full"
            required
          />
        </div>
        
        <div className="mb-6 w-full">
          <label htmlFor="image" className="block font-semibold">
            Select Image
          </label>
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleImage}
            className="mt-1 p-2 border border-gray-300 rounded-lg w-full"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
        >
          Save
        </button>
      </form>
      {product.image && (
        <div className="flex flex-col justify-center items-center mx-4 mt-6">
          <h1>Product image</h1>
          <img
            src={URL.createObjectURL(product.image)}
            alt="Selected Image"
            className="selected-image h-64 w-auto"
          />
        </div>
      )}
       <ToastContainer />
    </div>
  );
}

export default AddProduct;
