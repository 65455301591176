import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar"; // Import the Sidebar component
import UserVehicles from "./UserVehicles";
import UserExpenses from "./UserExpenses";
import UserGiftRequests from "./UserGiftRequests";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGiftRequestsByUserId,
  selectGiftRequestsByUserId,
} from "../app/features/giftRequestByUserID";
import {
  fetchExpenseDetailsByUserId,
  selectExpenseDetailsByUserId,
} from "../app/features/ExpenseDetailByID";
import {
  fetchVehicleDetailById,
  selectVehicleDetailById,
} from "../app/features/VehicleDetailByID";
import {
  fetchUserDetailByID,
  selectUserDetail,
} from "../app/features/userDetailById";
function UserDetails() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [activeSection, setActiveSection] = useState("vehicles");
  const [localLoading, setLocalLoading] = useState(true);

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const dispatch = useDispatch();
  const {
    giftRequests,
    isLoading: giftRequestsLoading,
    error: giftRequestsError,
  } = useSelector(selectGiftRequestsByUserId);
  const {
    expenseDetails,
    isLoading: expenseDetailsLoading,
    error: expenseDetailsError,
  } = useSelector(selectExpenseDetailsByUserId);
  const {
    vehicleDetail,
    isLoading: vehicleDetailLoading,
    error: vehicleDetailError,
  } = useSelector(selectVehicleDetailById);
  const {
    userDetail,
    isLoading: userDetailLoading,
    error: userDetailError,
  } = useSelector(selectUserDetail);
  useEffect(() => {
    console.log(id);
    dispatch(fetchGiftRequestsByUserId(id));
    dispatch(fetchExpenseDetailsByUserId(id));
    dispatch(fetchVehicleDetailById(id)); 
    dispatch(fetchUserDetailByID(id));// Call the fetchExpenseDetailsByUserId action
  }, [dispatch, id]);

  useEffect(() => {
    if (!giftRequestsLoading && !giftRequestsError && giftRequests) {
      console.log("Gift Requests Data: ", giftRequests);
    }
  }, [giftRequests, giftRequestsLoading, giftRequestsError]);

  useEffect(() => {
    if (!expenseDetailsLoading && !expenseDetailsError && expenseDetails) {
      console.log("Expense Details Data: ", expenseDetails);
    }
  }, [expenseDetails, expenseDetailsLoading, expenseDetailsError]);

  useEffect(() => {
    if (!vehicleDetailLoading && !vehicleDetailError && vehicleDetail) {
      console.log("Vehicle Detail Data: ", vehicleDetail);
    }
  }, [vehicleDetail, vehicleDetailLoading, vehicleDetailError]);
  useEffect(() => {
    if (!userDetailLoading && !userDetailError && userDetail) {
      console.log("userDetail Data: ", userDetail);
    }
  }, [userDetail, userDetailLoading, userDetailError]);

  const mockUser = {
    userDetail: userDetail,
    // profileImage: "user-profile.jpg", // Replace with actual image URL
    vehicles: vehicleDetail,
    giftRequests: giftRequests,
    expenses: expenseDetails,
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="flex " style={{ height: "calc(100vh-64px)" }}>
      <div className="w-16 md:hidden flex items-center justify-center cursor-pointer bg-gray-200 absolute top-15 left-0">
        <div onClick={handleSidebarToggle}>
          <svg
            className="w-6 h-6 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </div>
      </div>
      <div
        className={`w-60 min-h-screen sm:w-auto ${
          isSidebarOpen ? "" : "hidden"
        } sm:flex`}
      >
      
        <Sidebar user={mockUser.userDetail} onSectionClick={handleSectionClick}
        isLoading={userDetailLoading}
        error={userDetailError} />
      </div>
      {/* Right-side content based on the active section */}
      <div className="flex-grow p-4 overflow-auto min-h-96">
        {activeSection === "vehicles" && (
          <UserVehicles
            vehicles={mockUser.vehicles}
            isLoading={vehicleDetailLoading}
            error={vehicleDetailError}
          />
        )}
        {activeSection === "expenses" && (
          <UserExpenses
            expenses={mockUser.expenses}
            isLoading={expenseDetailsLoading}
            error={expenseDetailsError}
          />
        )}
        {activeSection === "gifts" && (
          <UserGiftRequests
            giftRequests={mockUser.giftRequests}
            isLoading={giftRequestsLoading}
            error={giftRequestsError}
          />
        )}
      </div>
    </div>
  );
}

export default UserDetails;
