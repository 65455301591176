// allUsersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "../Api";

export const fetchAllUsers = createAsyncThunk("allUsers/fetchAllUsers", async ({ fromDate, toDate }, { rejectWithValue }) => {
  console.log("kkk",{ fromDate, toDate })
  try {
    const response = await getAllUsers({ fromDate, toDate });
    console.log("kkk",response)
    return response.data; // Assuming the API response directly contains the list of all users
  } catch (error) {
    console.log("kkk",error)
    return rejectWithValue(error.message || "Failed to fetch all users");
  }
});

const allUsersSlice = createSlice({
  name: "allUsers",
  initialState: {
    userList: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userList = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});



export default allUsersSlice.reducer;
