import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { BeatLoader } from "react-spinners";

const DeleteConfirmationModal = ({
  isOpen,
  handleClose,
  handleConfirm,
  deleteLoading,
}) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 2,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Are you sure you want to delete this expense from your database ?
        </Typography>
        {deleteLoading ? (
          <div style={{ textAlign: "center", marginTop: "16px" }}>
            <BeatLoader size={8} color={"#000"} />
            <Typography variant="body2" component="p" sx={{ marginTop: 1 }}>
              Deleting...
            </Typography>
          </div>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-800 hover:bg-gray-400"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white hover:bg-red-600"
              onClick={handleConfirm}
            >
              Delete
            </button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
