import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getExpenses } from "../Api";

export const fetchExpenseDetails = createAsyncThunk(
  "expenseDetails/fetchExpenseDetails",
  async ({ fromDate, toDate }) => {
    const response = await getExpenses({ fromDate, toDate });
   
    return response;
  }
);

const Allexpenses = createSlice({
  name: "Allexpenses",
  initialState: {
    expenseDetails: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenseDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchExpenseDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.expenseDetails = action.payload.data;
      })
      .addCase(fetchExpenseDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectExpenses = (state) => state.Allexpenses;
export default Allexpenses.reducer;
