import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteExpense } from "../Api";
import { fetchExpenseDetails } from "./getAllExpenses";

export const deleteExpenseById = createAsyncThunk(
  "deleteExpense/deleteExpenseById",
  async ({ expenseId, formattedFromDate, formattedToDate }, { dispatch }) => {
    console.log("deleteExpense", expenseId);
    const response = await deleteExpense(expenseId);

    if (response.isSuccess) {
      dispatch(
        fetchExpenseDetails({
          fromDate: formattedFromDate,
          toDate: formattedToDate,
        })
      ); // You may need to pass fromDate and toDate if required
    }
    return response;
  }
);

const deleteExpenseSlice = createSlice({
  name: "deleteExpense",
  initialState: {
    deletedExpense: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteExpenseById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccess = false;
      })
      .addCase(deleteExpenseById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deletedExpense = action.payload.data;
        state.isSuccess = true;
      })
      .addCase(deleteExpenseById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.isSuccess = false;
      });
  },
});

export const selectDeletedExpense = (state) =>
  state.deleteExpense.deletedExpense;
export default deleteExpenseSlice.reducer;
