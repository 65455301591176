import React, { useState } from "react";
import Modal from "react-modal";


function UserExpenses({expenses , isLoading, error }) {
  const [expensesData, setExpensesData] = useState(expenses);
  const [selectedImage, setSelectedImage] = useState(null);

  const formatDate = (inputDate) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit"
    };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  };


  if (isLoading) {
    return <div className="flex justify-center items-center h-screen text-4xl font-bold">Loading expenses...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-4xl font-bold text-red-500">Error loading expenses: {error}</div>;
  }

  if (!expensesData || expensesData.length === 0) {
    return <div className="flex justify-center items-center h-screen text-4xl font-bold">No expenses available.</div>;
  }
  

  const handleStatusChange = (expenseId, newStatus) => {
    // Update the status of the expense with the given ID
    const updatedExpenses = expensesData.map((expense) => {
      if (expense.id === expenseId) {
        return { ...expense, status: newStatus };
      }
      return expense;
    });

    setExpensesData(updatedExpenses);
  };

  // Function to open the modal with the selected image
  const openImageFullScreen = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Function to close the full screen image
  const closeImageFullScreen = () => {
    setSelectedImage(null);
  };

  return (
    <div className="m-4 ">
      <h2 className="text-2xl font-semibold mb-4  bg-slate-300 rounded-md  ps-4">
        Expenses
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {expensesData.map((expense) => (
          <div key={expense.id} className="border rounded-lg overflow-hidden">
            <img
              src={expense.fileName}
              alt={`Expense ${expense.id}`}
              className="w-full h-48 object-cover "
              onClick={() => openImageFullScreen(expense.fileName)}
            />
            <div className="p-4">
              <p className="text-lg font-semibold">{formatDate(expense.date)}</p>
              <p className="text-gray-600">
                Transaction No: {expense.transactionNo}
              </p>
              <div className="flex justify-between mt-2">
                <p>Cost: {expense.price}</p>
                <p>Quantity: {expense.qty}ltr</p>
              </div>
              <p>Status: {expense.status}</p>
              {expense.status === "Pending" && (
                <div className="mt-4">
                  <button
                    className="px-4 py-2 bg-green-500 text-white rounded-lg mr-2"
                    onClick={() => handleStatusChange(expense.id, "Accepted")}
                  >
                    Accept
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded-lg"
                    onClick={() => handleStatusChange(expense.id, "Rejected")}
                  >
                    Reject
                  </button>
                </div>
              )}
              {expense.status !== "Pending" && (
                <div className="mt-4">
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                    onClick={() => handleStatusChange(expense.id, "Pending")}
                  >
                    Review Again
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="fixed top-12 left-0 h-screen w-screen bg-black bg-opacity-90 flex items-center justify-center">
          <button
            onClick={closeImageFullScreen}
            className="absolute top-10 right-12 text-white text-xl cursor-pointer"
          >
            Close
          </button>
          <img src={selectedImage} alt="Full Screen" className="h-4/5 " />
        </div>
      )}
    </div>
  );
}

export default UserExpenses;
