import React,{useState} from "react";
import NavBar from "../components/NavBar";
import { Route, Routes } from "react-router-dom";
import Userslist from "./Userslist";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddProduct from "./AddProduct";
import MasterPage from "./MasterPage";
import ProductList from "./ProductList";
import UserDetails from "./UserDetail";
import GiftRequestsPage from "./GiftRequestsPage";
import AllExpenses from "./AllExpenses";
function HomePage() {

  const [navbarHeight, setNavbarHeight] = useState(0);

  const handleNavbarHeightChange = (height) => {
    setNavbarHeight(height);
  };
  return (
    <div>
      <NavBar onNavbarHeightChange={handleNavbarHeightChange}/>
      {/* <Card sx={{ maxWidth: 345, margin: 'auto', marginTop: 16 }}>
        <CardMedia
          component="img"
          alt="Image"
          height="140"
          image="/path-to-your-image.jpg" // Replace with your image path
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Card Title
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Some description or content for the card.
          </Typography>
          <Button variant="contained" color="primary">
            Action Button
          </Button>
        </CardContent>
      </Card> */}
      <div style={{ marginTop: "64px" }}>
        {/* Your router content */}
        <Routes>
          <Route path="/" element={<Userslist navbarHeight={navbarHeight}/>} />
          <Route path="/addproduct" element={<AddProduct />} />
          <Route path="/masterpage" element={<MasterPage />} />
          <Route path="/productlist" element={<ProductList />} />
          <Route path="/user/:id" element={<UserDetails />} />
          <Route path="/redeemrequests" element={<GiftRequestsPage />} />
          <Route path="/allexpenses" element={ <AllExpenses/>} />
          {/* Add more routes here */}
        </Routes>
      </div>
    </div>
  );
}

export default HomePage;
