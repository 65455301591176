import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./features/AuthSlice";
import productReducer from "./features/ProductSlice";
import allUsersReducer from "./features/allUserSlice";
import redeemRequestsReducer from "./features/redeemRequestsSlice";
import giftRequestsByUserIdReducer from "./features/giftRequestByUserID";
import expenseDetailByUserIdReducer from "./features/ExpenseDetailByID";
import expenseDetailsReducer from "./features/getAllExpenses";
import vehicleDetailByIdReducer from "./features/VehicleDetailByID";
import userDetailByIdReducer from "./features/userDetailById";
import masterRuleReducer from "./features/masterRuleSlice";
import addProductReducer from "./features/addProductSlice";
import giftStatusReducer from "./features/giftStatusSlice";
import updateExpenseReducer from "./features/updateExpenseSlice";
import deleteExpenseByIdReducer from "../app/features/deleteExpenseSlice";
export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    products: productReducer,
    allUsers: allUsersReducer,
    redeemRequests: redeemRequestsReducer,
    giftRequestsByUserId: giftRequestsByUserIdReducer,
    expenseDetailByUserId: expenseDetailByUserIdReducer,
    vehicleDetailById: vehicleDetailByIdReducer,
    userDetailById: userDetailByIdReducer,
    masterRule: masterRuleReducer,
    addproducts: addProductReducer,
    giftStatus: giftStatusReducer,
    allExpenseDetails:expenseDetailsReducer,
    updateExpense: updateExpenseReducer,
    deleteExpense:deleteExpenseByIdReducer,
  },
});
