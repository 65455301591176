import React, { useEffect } from "react";

function UserGiftRequests({ giftRequests, isLoading, error }) {
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen text-4xl font-bold">
        Loading gift requests...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-4xl font-bold">
        Error loading gift requests: {error}
      </div>
    );
  }

  if (!giftRequests || giftRequests.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen text-4xl font-bold">
        No gift requests made by the user.
      </div>
    );
  }
  const formatDate = (inputDate) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  };

  return (
    <div className="">
      <h2 className="text-2xl font-semibold mb-4 bg-slate-300 rounded-md ps-4">
        Redeemed Product detail
      </h2>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse whitespace-no-wrap">
          <thead>
            <tr style={{ backgroundColor: "skyblue" }}>
              <th className="px-4 py-2">Product Image</th>
              <th className="px-4 py-2">Product Name</th>
              <th className="px-4 py-2">Points</th>
              <th className="px-4 py-2">Order Date</th>
              {/* <th className="px-4 py-2">Delivery Date</th> */}
              <th className="px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {giftRequests.map((request) => (
              <tr key={request.id}>
                <td className="px-4 py-2">
                  <img
                    src={request.fileName}
                    alt={request.productName}
                    className="h-16 w-16 object-cover "
                  />
                </td>
                <td className="px-4 py-2">{request.productName}</td>
                <td className="px-4 py-2">{request.redeemPoints}</td>
                <td className="px-4 py-2">{formatDate(request.createdDate)}</td>
                {/* <td className="px-4 py-2">{request.deliveryDate}</td> */}
                <td
                  className={`px-4 py-2 ${
                    request.status === "Delivered"
                      ? "text-green-500"
                      : "text-orange-500"
                  }`}
                >
                  {request.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default UserGiftRequests;
