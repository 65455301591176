import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createProduct } from "../Api"; // Import your createProduct function here

export const addProduct = createAsyncThunk(
  "products/add",
  async (productData) => {
    try {
      const response = await createProduct(productData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const addProductSlice = createSlice({
  name: "products",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addProduct.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to add product.";
      });
  },
});

export default addProductSlice.reducer;
