import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsers } from "../app/features/allUserSlice";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const columns = [
  { id: "srno", label: "Sr No.", minWidth: 100 },
  { id: "name", label: "Name", minWidth: 100 },
  {
    id: "mobileNumber",
    label: "Mobile Number",
    minWidth: 250,
    align: "center",
  },
  {
    id: "referralUserCount",
    label: "Referral Count",
    minWidth: 50,
    align: "center",
  },
  {
    id: "password",
    label: "Password",
    minWidth: 100,
    align: "center",
  },
  {
    id: "createdDate",
    label: "Registration Date",
    minWidth: 200,
    format: (value) => formatDate(value),
  },

  {
    id: "totalPoints",
    label: "Incentive Points",
    minWidth: 80,
    align: "right",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 80,
    align: "center",
    format: (row) => (
      <Button variant="outlined" color="primary">
        See User Detail
      </Button>
    ),
  },
];

const formatDate = (inputDate) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(inputDate).toLocaleDateString(undefined, options);
};

export default function Userslist(props) {
  const { navbarHeight } = props;
  console.log("navh", navbarHeight);
  const dispatch = useDispatch();
  const { userList, isLoading, error } = useSelector((state) => state.allUsers);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchHeight, setSearchHeight] = useState();
  const [selectedFromDate, setSelectedFromDate] = useState(() => {
    const currentDate = new Date();
    const lastTwoMonths = new Date();
    lastTwoMonths.setMonth(currentDate.getMonth() - 2);

    return lastTwoMonths;
  });
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const searchBarRef = useRef(null);
  // const navbarRef = useRef(null);

  // useEffect(() => {
  //   const searchBarHeight = searchBarRef.current.offsetHeight;

  //   setSearchHeight(searchBarHeight);
  //   console.log(searchBarHeight);
  // }, []);
  // const tableHeight = window.innerHeight - navbarHeight - searchHeight;
  // console.log("win", window.innerHeight);
  // console.log("table", tableHeight);
  const formatDateToYYYYMMDD = (date) => {
    if (!date) {
      return "";
    }

    const d = date.getDate();
    const M = date.getMonth() + 1;
    const y = date.getFullYear();

    const formattedDate = `${y}-${M < 10 ? "0" + M : M}-${
      d < 10 ? "0" + d : d
    }`;

    return formattedDate;
  };
  // const fromDate = "2023-10-11";
  // const toDate = "2023-10-18";
  console.log({ selectedFromDate, selectedToDate });
  const formattedFromDate = formatDateToYYYYMMDD(selectedFromDate);
  const formattedToDate = formatDateToYYYYMMDD(selectedToDate);
  console.log({ formattedFromDate, formattedToDate });
  const fromDate = "2023-10-03 17:30:00";
  const toDate = "2023-10-18 17:16:50";
  useEffect(() => {
    // Fetch all users when the component mounts
    dispatch(
      fetchAllUsers({
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      })
    );
    console.log("expenses", { fromDate, toDate });
    // console.log("userss", userList);
  }, [dispatch]);

  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-center text-2xl font-bold mb-4">
          your data is loading, please wait...
        </h1>
        <div className="mb-8">
          <BeatLoader size={50} color={"black"} loading={true} />
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const filteredUserList = userList
    ? userList.filter((user) => {
        const searchTerms = searchQuery.toLowerCase().split(" ");
        return searchTerms.every(
          (term) =>
            user.name.toLowerCase().includes(term) ||
            user.mobileNumber.includes(term)
        );
      })
    : [];
  const exportToExcel = () => {
    const dataToExport = filteredUserList.map((row, index) => ({
      SrNo: index + 1,
      Name: row.name,
      MobileNumber: row.mobileNumber,
      RegisterDATE: formatDate(row.createdDate),
      TotalPoints: row.totalPoints,
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "UserList");
    XLSX.writeFile(wb, "userList.xlsx");
  };
  const handleDateFromChange = (date) => {
    setSelectedFromDate(date);
  };

  const handleDateToChange = (date) => {
    setSelectedToDate(date);
  };
  const CustomInput = ({ value, onClick, placeholdr }) => (
    <input
      type="text"
      placeholder={placeholdr}
      value={value}
      onClick={onClick}
      style={{ height: "40px", borderRadius: "5px", width: "150px" }}
    />
  );

  const handleSearch = () => {
    dispatch(
      fetchAllUsers({
        fromDate: formattedFromDate,
        toDate: formattedToDate,
      })
    );
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#e0e0e0",
        }}
      >
        <div style={{ backgroundColor: "#e0e0e0" }}>
          <input
            type="text"
            placeholder="Search by name and mobile no."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              margin: "10px",
              height: "40px",
              borderRadius: "5px",
              width: "100%",
            }}
          />
        </div>
        <div style={{ display: "flex", margin: "10px" }}>
          <div style={{ marginRight: "5px", marginLeft: "5px" }}>
            <DatePicker
              selected={selectedFromDate}
              onChange={handleDateFromChange}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput placeholdr="From Date" />}
            />
          </div>
          <div style={{ marginRight: "5px" }}>
            <DatePicker
              selected={selectedToDate}
              onChange={handleDateToChange}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInput placeholdr="To Date" />}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            style={{ marginRight: "10px" }}
          >
            Search
          </Button>
          <Button variant="contained" color="success" onClick={exportToExcel}>
            Download User List
          </Button>
        </div>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 420, overflowY: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUserList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No matching users found.
                  </TableCell>
                </TableRow>
              ) : (
                filteredUserList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value =
                          column.id === "srno" ? index + 1 : row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "action" ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => navigate(`/home/user/${row.id}`)}
                              >
                                See User Detail
                              </Button>
                            ) : column.format ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredUserList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <Button variant="contained" color="primary" onClick={exportToExcel}>
        Download User List
      </Button> */}
    </div>
  );
}
