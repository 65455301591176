import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getExpenseDetailsByUserId } from "../Api";

export const fetchExpenseDetailsByUserId = createAsyncThunk(
  "expenseDetailByUserId/fetchExpenseDetailsByUserId",
  async (userId) => {
    const response = await getExpenseDetailsByUserId(userId);
    return response;
  }
);

const expenseDetailByUserIdSlice = createSlice({
  name: "expenseDetailByUserId",
  initialState: {
    expenseDetails: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenseDetailsByUserId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchExpenseDetailsByUserId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.expenseDetails = action.payload.data;
      })
      .addCase(fetchExpenseDetailsByUserId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectExpenseDetailsByUserId = (state) => state.expenseDetailByUserId;
export default expenseDetailByUserIdSlice.reducer;
