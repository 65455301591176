import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMasterRule, createRule } from "../Api"; // Import your apiCall function here

export const fetchMasterRule = createAsyncThunk(
  "masterRule/fetch",
  async () => {
    try {
      const response = await getMasterRule();
      console.log(response);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const createNewRule = createAsyncThunk(
  "rules/create",
  async (ruleData) => {
    try {
      const response = await createRule(ruleData); // Call the createRule API function
      console.log("New rule created successfully:", response);
      return response; // Assuming the API response contains the created rule data
    } catch (error) {
      throw error;
    }
  }
);

const masterRuleSlice = createSlice({
  name: "masterRule",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMasterRule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMasterRule.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchMasterRule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch master rule.";
      })
      .addCase(createNewRule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewRule.fulfilled, (state, action) => {
        const updatedRule = action.payload;

        const existingRuleIndex = state.data.findIndex(
          (rule) => rule.id === updatedRule.id
        );

        if (existingRuleIndex !== -1) {
          state.data[existingRuleIndex] = updatedRule;
        }
      })
      .addCase(createNewRule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to create new rule.";
      });
  },
});

export default masterRuleSlice.reducer;
