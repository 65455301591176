import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getGiftRequests } from '../Api'; // Import your API function to fetch gift requests

export const fetchGiftRequests = createAsyncThunk('redeemRequests/fetchGiftRequests', async () => {
  const response = await getGiftRequests();
  return response; // Assuming your API response has a `data` property containing the gift requests
});

const redeemRequestsSlice = createSlice({
  name: 'redeemRequests',
  initialState: {
    giftRequests: [], // Initial state for gift requests
    isLoading: false,
    error: null,
  },
  reducers: {
    // Other synchronous actions can go here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGiftRequests.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchGiftRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.giftRequests = action.payload.data;
      })
      .addCase(fetchGiftRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { /* Other synchronous action creators can be exported here if needed */ } = redeemRequestsSlice.actions;
export default redeemRequestsSlice.reducer;
