import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { useDispatch, useSelector } from "react-redux";

import RegisterPage from "./pages/RegisterPage";
import NavBar from "./components/NavBar";
import HomePage from "./pages/HomePage";

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // const auth= useSelector((state) => state.auth.user.data.user);
  useEffect(() => {
    // console.log(auth);
    console.log(isLoggedIn);
  });
  return (
    <Router>
      {/* <NavBar/> */}
      <Routes>
        <Route
          exact
          path="/"
          element={isLoggedIn ? <Navigate to="/home" /> : <LoginPage />}
        ></Route>
        <Route exact path="/registerpage" element={<RegisterPage />}></Route>
        <Route
          index 
          path="/home/*"
          element={isLoggedIn ? <HomePage /> : <Navigate to="/" />}
        ></Route>
      </Routes>
    </Router>
  );
}

export default App;
