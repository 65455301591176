import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductList } from "../app/features/ProductSlice";
import { BeatLoader } from "react-spinners";
function ProductList() {
  const dispatch = useDispatch();
  const { productList, isLoading, error } = useSelector((state) => state.products);

  useEffect(() => {
    // Fetch the product list when the component mounts
    dispatch(fetchProductList());
    console.log(productList)
  }, [dispatch]);

  if (isLoading) {
    return(
       <div className="flex flex-col items-center justify-center h-screen">
    <h1 className="text-center text-2xl font-bold mb-4">
      your data is loading, please wait...
    </h1>
    <div className="mb-8">
      <BeatLoader size={50} color={"black"} loading={true} />
    </div>
  </div>
  )}

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!productList || productList.length === 0) {
    return <div>There are no products available.</div>;
  }

  return (
    <div className="flex flex-wrap justify-center">
      {productList.map((product, index) => (
        <div
          key={index}
          className="w-80 md:w-1/2 lg:w-1/3 xl:w-1/4 p-4 flex flex-col items-center"
        >
          <div className="border border-gray-300 p-4 rounded-lg max-w-xs"
          style={{ height: "300px" }}>
            <img
              src={product.fileName}
              alt={`Product ${index}`}
              className="w-full h-40 object-cover mb-2"
              style={{ objectFit: "cover" }} 
            />
            <h2 className="text-lg font-semibold">{product.name}</h2>
            <p className="text-gray-600">Points Needed: {product.point}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductList;
