import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProductList } from "../Api";

export const fetchProductList = createAsyncThunk("products/fetchProductList", async (_, { rejectWithValue }) => {
  try {
    const response = await getProductList(); // Call the getProductList method from your API file
    return response.data; // Assuming your API response has a 'data' property containing the product list
  } catch (error) {
    return rejectWithValue(error.message || "Failed to fetch product list");
  }
});

const productSlice = createSlice({
  name: "products",
  initialState: {
    productList: null ,// Initial state for the product list
    isLoading: false,
    error: null,
  },
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProductList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productList = action.payload;
      })
      .addCase(fetchProductList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// export const { /* your additional reducers here */ } = productSlice.actions;

export default productSlice.reducer;
