import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BeatLoader } from "react-spinners";
import {
  fetchMasterRule,
  createNewRule,
} from "../app/features/masterRuleSlice";
function MasterPage() {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.masterRule);
  const [rules, setRules] = useState([]);
  const [points, setPoints] = useState("");
  const [quantity, setQuantity] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    // Fetch master rule data when the component mounts
    dispatch(fetchMasterRule());
    console.log("64", data[0]);
  }, [dispatch]);
  useEffect(() => {
    // Set initial state based on the first item in the data array
    if (data && data.length > 0) {
      setPoints(data[0].point || "");
      setQuantity(data[0].qty || "");
      setContent(data[0].rulesParagraph || "");
    }
  }, [data]);

  const handleChange = (htmlContent) => {
    setContent(htmlContent);
  };

  const handleSave = () => {
    // Create a new rule object with the updated data
    const ruleData = {
      ID: data[0].id,
      Point: points.toString(),
      Qty: quantity,
      RulesParagraph: content,
      MinimumQty: 0,
      ReferalPoint: 30,
      CreatedBy: 1,
      updatedDate: new Date(),
    };

    // Dispatch the createNewRule action to create/update the rule
    dispatch(createNewRule(ruleData))
      .unwrap()
      .then(() => {
        dispatch(fetchMasterRule());
        // Handle success, maybe show a success message or navigate to another page
        console.log("Rule created/updated successfully!");
      })
      .catch((error) => {
        // Handle error, show an error message to the user
        console.error("Failed to create/update rule:", error);
      });
  };

  return (
    <div className="flex justify-center items-center  overflow-y-auto">
      {loading ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <h1 className="text-center text-2xl font-bold mb-4">
            your data is loading, please wait...
          </h1>
          <div className="mb-8">
            <BeatLoader size={50} color={"black"} loading={true} />
          </div>
        </div>
      ) : (
        <div className="w-96 p-4">
          <h1 className="text-2xl font-semibold mb-4 bg-slate-300 rounded-md p-4">
            Rules Configuration
          </h1>
          <div className="mb-4">
            <label htmlFor="points" className="block font-semibold">
              Points:
            </label>
            <input
              type="number"
              id="points"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
              className="border border-gray-300 p-2 rounded-lg w-full"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="quantity" className="block font-semibold">
              Quantity (Liters):
            </label>
            <input
              type="number"
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              className="border border-gray-300 p-2 rounded-lg w-full"
            />
          </div>
          <div className="mb-4">
            <ReactQuill
              value={content}
              onChange={setContent}
              className=" h-32"
            />
          </div>
          <button
            onClick={handleSave}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-16"
          >
            Save Rules
          </button>
        </div>
      )}
    </div>
  );
}

export default MasterPage;
