import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { fetchMasterRule } from "../app/features/masterRuleSlice";
import { useDispatch, useSelector } from "react-redux";
const ExpenseEditModal = ({
  isOpen,
  handleClose,
  expenseData,
  handleUpdate,
}) => {
  const {
    id,
    transactionNo: initialTransactionNo,
    date,
    qty: initialQty,
    point: initialPoint,
  } = expenseData;
  const { data, loading, error } = useSelector((state) => state.masterRule);

  const [initialpoints, setInitialPoints] = useState("");
  const [qty, setQty] = useState(initialQty);
  const [pointsperltr, setPointsPerlTr] = useState("");
  const [initialquantity, setInitialQuantity] = useState("");
  const [transactionNo, setTransactionNo] = useState(initialTransactionNo);
  const [point, setPoint] = useState(initialPoint);
  const dispatch = useDispatch();

  useEffect(() => {
    // Update the component state when expenseData changes
    setQty(initialQty);
    setTransactionNo(initialTransactionNo);
    setPoint(initialPoint);
  }, [initialQty, initialTransactionNo, initialPoint]);

  useEffect(() => {
    // Fetch master rule data when the component mounts
    dispatch(fetchMasterRule());
  }, [dispatch]);

  useEffect(() => {
    // Set initial state based on the first item in the data array
    if (data && data.length > 0) {
      setInitialPoints(data[0].point || "");
      setInitialQuantity(data[0].qty || "");
    }
  }, [data]);

  useEffect(() => {
    // Update pointsperltr when qty or point changes
    if (initialpoints && initialquantity) {
      console.log(initialquantity, initialpoints);
      const calculatedPointsPerlTr =
        parseFloat(initialpoints) / parseFloat(initialquantity);
      setPointsPerlTr(calculatedPointsPerlTr.toFixed(2)); // Adjust to the desired decimal places
    }
  }, [initialquantity, initialpoints]);

  const storedUserData = localStorage.getItem("userData");
  const handleQtyChange = (newQty) => {
    setQty(newQty);
    console.log({ newQty, pointsperltr });

    // Validate newQty and pointsperltr
    if (!newQty || !pointsperltr || isNaN(newQty) || isNaN(pointsperltr)) {
      // Handle invalid input, e.g., display an error message or reset the values
      console.error("Invalid input for qty or pointsperltr");

      // Set "Points Earned" to 0
      setPoint(0);
      return;
    }

    // Update "Points Earned" based on qty
    const calculatedPoints = parseFloat(newQty) * parseFloat(pointsperltr);

    // Validate the calculatedPoints
    if (!isNaN(calculatedPoints)) {
      setPoint(calculatedPoints.toFixed(2));
    } else {
      // Handle invalid calculation result, e.g., display an error message or reset the value
      console.error("Invalid calculation result for Points Earned");

      // Set "Points Earned" to 0
      setPoint(0);
    }
  };
  // Parse the JSON string to an object
  const userData = JSON.parse(storedUserData);

  // Access the userRole property
  const userRole = userData.userRole;
  const handleUpdateClick = () => {
    // Check if values are changed before updating
    if (
      transactionNo !== initialTransactionNo ||
      qty !== initialQty ||
      point !== initialPoint
    ) {
      // Implement your update logic here
      handleUpdate({
        NewTransactionNo: transactionNo,
        NewQty: qty,
        NewPoint: point,
        UpdatedBy: userRole,
      });
    }
    handleClose();
  };

  const formatDate = (inputDate) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  };

  const labelStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    color: "black", // Adjust color as needed
  };

  const inputStyle = {
    padding: "10px",

    borderRadius: "5px",
    fontSize: "16px",
  };

  const buttonStyle = {
    marginRight: "8px",
    fontSize: "16px",
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Edit Expense</DialogTitle>
      <DialogContent>
        <div style={containerStyle}>
          <div>
            <label style={labelStyle}>Transaction No.:</label>
            <input
              type="text"
              value={transactionNo}
              onChange={(e) => setTransactionNo(e.target.value)}
              style={inputStyle}
            />
          </div>
          <div>
            <label style={labelStyle}>Date: {formatDate(date)}</label>
          </div>
          <div>
            <label style={labelStyle}>Liter Purchased:</label>
            <input
              type="text"
              value={qty}
              onChange={(e) => handleQtyChange(e.target.value)}
              style={inputStyle}
            />
          </div>
          <div>
            <label style={labelStyle}>Points Earned:</label>
            <input
              type="text"
              value={point}
              onChange={(e) => setPoint(e.target.value)}
              style={inputStyle}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleUpdateClick}
          color="primary"
          variant="contained"
          style={buttonStyle}
        >
          Update
        </Button>
        <Button onClick={handleClose} color="secondary" style={buttonStyle}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExpenseEditModal;
