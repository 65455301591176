import React from "react";

function UserVehicles({ vehicles, isLoading, error }) {
  if (isLoading) {
    return <div className="flex justify-center items-center h-screen text-4xl font-bold">Loading vehicles...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-4xl font-bold">Error loading vehicles: {error}</div>;
  }

  if (!vehicles) {
    return <div className="flex justify-center items-center h-screen text-4xl font-bold">No vehicles added by the user.</div>;
  }

  if (vehicles.length === 0) {
    return <div className="flex justify-center items-center h-screen text-4xl font-bold">No vehicles added by the user.</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4 bg-slate-300 rounded-md ps-4">
        Vehicles
      </h2>
      {vehicles.map((vehicle) => (
        <div
          key={vehicle.id}
          className="bg-white p-4 shadow-md rounded-md mb-4"
        >
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-xl font-semibold">
                {vehicle.make} {vehicle.model}
              </h3>
              <p className="text-gray-600">
                Year: {vehicle.year} | Fuel Type: {vehicle.fuelType}
              </p>
            </div>
            <div>
              <img
                src={vehicle.fileName}
                alt={`${vehicle.make} ${vehicle.model}`}
                className="w-32 h-24 object-cover rounded-md"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default UserVehicles;
